.major-card {
    text-align: center;

    .image {
        width: 100%;
        max-height: 200px;
        
        @include breakpoint(large){
            max-height: 260px;
        }
     
        img {
            width: 100%;
            max-width: none;
        }
    }

    p { margin-bottom: 1rem; }

    a {
        text-decoration: none;
        color: $primary-gray;

        &:hover {
            color: $primary-blue;
        }
    }

    h5 {
        margin: 1rem 0;
    }

    .btn-link {
        margin: 1rem 0;
    }

    @include breakpoint(large) {
        @include xy-cell(6);
    }

     @include breakpoint(xlarge) {
        @include xy-cell(4);
    }

}

.major-loader {
    width: 100%;
    text-align: center;

    &.accordion  {
        border: none;
    }
}

.major-card-list {

    @include xy-grid;

    .major-card {
        margin-bottom: 2em;
    }

    .major-loader {
        list-style: none;
    }
}
