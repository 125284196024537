.tabs {
    text-align: center;
    border: none;
    background: transparent;

    &.vertical {
        .tabs-title > a {
           padding-top: 0.75rem;
           padding-bottom: 0.75rem;
        }

    }
}

.tabs-centered {

    .tabs-title {
        display: inline-block;
        float: none;

        > a {
            font-size: 1rem;
            padding: .75rem;
        }
    }
}

.tabs-panel {
    padding: 2em 0 0 0;
    display: block;
}

.tabs-title {

    &.is-active {
        &> a {
            &.button {
                &:hover {
                    background: $secondary-blue !important;
                    color: white !important;
                }
            }
        }
    }
    
    &> a {
        font-size: 1rem;
        padding: .8rem 1.2rem;
        text-transform: none;
        text-decoration: none;
        background: $white;
        border: 2px solid rgba(43, 43, 43, 0.3);
        margin: 0 .3rem .5rem;
        font-weight: normal !important;

        &:hover {
            color: $primary-color;
            background: rgba(43, 43, 43, 0.1);
        }

        &:focus {
            background-color: $primary-color;
            color: #fff;

            &:hover {
                background-color: $primary-blue;
            }
        }

        &[aria-selected='true'] {
            background-color: $primary-color;
            color: #fff;
        }
    }
}

.tabs-content {
    border: none;
    background: transparent;
    *zoom: 1;
    display: block !important;
    padding: 1rem 1rem 0 1rem;

    &::before, &::after {
        content: "";
        display: table; 
    }

    &::after {
        clear: both; 
    }

    &> .tabs-panel {
        display: none;
        float: left;
        padding: 0.9375rem 0;

        &.is-active {
            animation: fadeIn 1s;
            opacity: 1;
            display: block;
        }
    }

    .inner {
        @include xy-grid;
    }

    .content {

        @include breakpoint(xlarge) {
            @include xy-cell(auto);
        }

        li {
            margin: 0 0 0.45em 1.5em;
        }

    }

    .media {

        @include breakpoint(xlarge) {
            @include xy-cell(5);
        }

    }

    li {
        margin-bottom: 1rem;
    }

}
 
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}