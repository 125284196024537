
.nav-sidebar {
    margin-bottom: 2rem;

    .section-title {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .menu {
        > li{
            font-size: 1rem;
        }

        > li > ul > li {
            font-size:  1rem;
        }
    
        a {
            transition: color 800ms;
            color: $primary-gray;
           
            &.subsection-title{
                font-weight: bold;
            }
            
            &:hover {
                color: darken($secondary-blue, 0);
                text-decoration: underline;
            }
        }

        .nested {
            margin-left: 1.6rem;
            margin-bottom: .5rem;
        }
    }
}

.page-menu {
    margin-left: -1rem;
}

.page-menu-header {
    position: relative;
    font-weight: bold;
    margin-bottom: 10px;

    .page-menu-trigger {
        width: 100%;
        margin: 0;
        position: relative;

        &::before {
            top: 15px;
            right: 20px;
            position: absolute;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f067";
        }

    }
}

.nested .is-active {
    font-weight: bold;
    padding: .25rem 1rem;

    a {
        background-color: transparent;
        color: $secondary-seagreen;
        padding: 0;
    }

}

.sidebar-nav {
    margin-bottom: 2.2rem;

    .menu {
        margin-left: .5rem;
        list-style: none;

       a {
            transition: color 800ms;
            color: $body-turquiose;
            padding: .3rem 0;
            line-height: 1.2;
            text-decoration: underline;
            
            &:hover {
                color: $secondary-orange;
            }
        }

    }
}
