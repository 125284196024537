.list-linked {

    list-style: none;
    margin-left: 0;
    max-width: 800px;

    li {
        margin-bottom: 5px;

        a {
            padding: .5rem .5rem .5rem 2rem;
            display: block;
            text-decoration: none;
            color: $secondary-blue;

            /* &:hover, &:focus {
                background-color: #eee;
            } */
        }
    }
}

.list-linked li a {

    position: relative;

    &:before {
        position: absolute;
        left: .5rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }

    &[target$="_blank"]:before {
        content: "\f360";
    }

    &[href$=".pdf"]:before {
        content: "\f1c1";
    }

    &[href$=".doc"]:before, [href$=".docx"]:before {
        content: "\f1c2";
    }

    &[href$=".xls"]:before, [href$=".xlsx"]:before, [href$=".csv"]:before {
        content: "\f1c3";
    }

    &[href$=".ppt"]:before, [href$=".pptxx"]:before {
        content: "\f1c4";
    }



}
