
.grid-container {
    position: relative;
    max-width: 52em;
    clear: both;
    
    @include breakpoint(large){
        max-width: 64em;
    }

    &.large {
        max-width: 70em;

        @include breakpoint(xlarge){
            max-width: 80em;
        }

        @include breakpoint(xxlarge){
            max-width: 85em;
            padding: 0 3rem;
        }
        
        @include breakpoint(xxxlarge){
            max-width: 90em;
            padding: 0;
        }
    }  

}

.page-utilities {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    border-top: 1px solid lighten($primary-gray, 70%);
    border-bottom: 1px solid lighten($primary-gray, 70%);
   
    @include show-for(large);

    .breadcrumbs {
        margin-bottom: 0;
        
        a:hover {
            text-decoration: none;
        }
        
        li {
            color: $primary-gray;
            font-weight: bold;
            font-size: 1rem;
            text-transform: none;

            .btn-link:after {
                height: 2px;
            }

            a {
                color: $primary-blue;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.page-content, #page-content {
    position: relative;
    padding: 1rem .5rem;
    
    @include breakpoint(large){
        padding: 4rem 0;
    }
   
}

.sidebar {
    @include breakpoint(large){
        padding-left: 5%;
    }

    &-container {
        padding: 10px 15px;
    }
}


.page-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.wave-pattern {
        
    &.blue {
        @include breakpoint(large){ background-image: url('../../assets/img/build-resources/wave-pattern-blue.png'); }
        &.opaque {
            background-color: #daebf5;
        }
    }

    &.gray {
        @include breakpoint(large){ background-image: url('../../assets/img/build-resources/wave-pattern-blue.png'); }
       
        &.opaque {
            background-color: lighten($primary-gray, 55%);
        }
    }

    &.turquiose {
        @include breakpoint(large){ background-image: url('../../assets/img/build-resources/wave-pattern-turquiose.png'); }

        &.opaque {
            background-color: lighten($secondary-turquiose, 50%);
        }
    }

    &.green {
        @include breakpoint(large){ background-image: url('../../assets/img/build-resources/wave-pattern-green.png'); }

        &.opaque {
            background-color: lighten($secondary-green, 45%);
        }
    }

    &.red {
        @include breakpoint(large){ background-image: url('../../assets/img/build-resources/wave-pattern-red.png'); }

        &.opaque {
            background-color: lighten($secondary-pink, 50%);
        }
    }

    @include breakpoint(large){
        background-color: transparent;
        background-position: top left;
        background-repeat: repeat-x;
    }
    
}

.closing-blocks {
    .image {
        overflow: hidden;
        position: relative;
        width: 100%;
        margin-bottom: 1rem;
        max-height: 350px;
        min-width: calc(100% - 2.5rem);
        background: #f2b41e;
    
        img { 
            min-height: 100%;
            width: auto;
            height: auto;
        }

        .overlay {
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 3;
            object-fit: none;
            object-position: top;
            overflow: visible;
            min-height: auto;
            width: auto;
            height: auto;
        }
        
    }
}

