.filter-box {
    background: rgba($white, 1);
    z-index: 5000;
    margin: 0;
    position: absolute;
    top: 135px;
    width: 100%;
    padding: 1.5rem;
    color: $primary-gray;
    border-bottom: 4px solid $primary-blue;
    display: none;
    text-align: center;

    .grid-container { padding: 0 !important; }

    .menu-list a { display: inline; }

    ul {
        list-style: none;
        margin: 0;
        line-height: 1.4;

        li {
            margin-bottom: .5rem;
        }
    }

    .close-modal {
        color: $primary-blue;
    }

    @include breakpoint(large) {
        width: 40%;
        margin-left: -20%;
        left: 50%;
    }

    &.full-width {
        text-align: left;
        border-top: 1px solid lighten($primary-gray, 65%);

        .close-modal {
            left: 50%;
            top: -56px;
            position: absolute;
            font-size: 1.5rem;
        }

        h4, h5 {
            color: white;
        }
        
        text-align: left;
        
        padding: 3rem 0;

        @include breakpoint(large) {
            width: 100%;
            margin: 0;
            left: 0;
        }

        .menu-list {

            display: block;

            a {
                font-size: 1.2rem;
                display: block;
                padding: .2rem 0;
                transition: all 600ms ease;
                padding: .1rem .4rem;
            }

            &.featured {
                padding: 1rem;
                /* border-right: 1px solid rgba(255, 255, 255, 0.2); */
                /* font-weight: bold; */
                background-color: rgba($primary-gray, .05);
            }
        }

    }  
}

.filter-controls {
    margin-bottom: 0.5em;
    clear: both;

    @include breakpoint(large) {
        margin-bottom: 1rem;
    }

    .filter-control {
        color: $primary-gray;
        text-decoration: none;
        display: inline-block;

        &:first-child {
            margin: 0 1rem 0;
        }
       
    }
}