.step {
    margin-bottom: 2rem;
    max-width: none;
    text-decoration: none;
    transition: color 1s;

    @include breakpoint(large) {
        margin-bottom: 0;
        padding: 1em;
    }

    h2, h3, h4, p, div {
        color: $primary-gray;
    }
    

    &:hover {
        h2, h3, h4 { color: $primary-blue; }
    }

    .step-container {
        text-align: left;
        @include xy-grid;

        .unit-container {
            @include xy-cell(12);
            
            @include breakpoint(large) {
                margin-bottom: 0;
                font-size: .75rem;
                @include xy-cell(2, true, 0);
           }

            @include breakpoint(xlarge) {
                text-align: center;
                padding: 0 .2rem .4rem .2rem;
                margin-bottom: 1.5rem;
                line-height: 0.5;
            }

            .step-text {
                display: inline;
                font-size: 1.5rem;
               
                @include breakpoint(xlarge) {
                    font-size: 1.8em;
                    margin-bottom: 0.9rem;
                    display: block;
                }
            }

           .unit {
                font-size: 1.5rem;
                font-weight: bold;
                display: inline;
                color: $primary-blue;

                @include breakpoint(xlarge) {
                    font-size: 2.5em;
                    display: block;
                }
            }
        }

        .inner {
            @include xy-cell(auto);
            @include xy-grid;
    
            .image {
               @include xy-cell(12);
                max-height: 165px;
                margin-bottom: 1rem;
                min-height: none;
            
                @include breakpoint(large){
                    @include xy-cell(3);
                }
            }
    
            .card-content {
                @include xy-cell(12);   
    
                @include breakpoint(large){
                    @include xy-cell(9);
                }

                .header {
                    margin-bottom: 15px;
                    
                    @include breakpoint(large){
                        margin-bottom: .3rem;
                    }
                }
            }
    
        }

    }

    &.split-column {
        max-width: none;
        @include xy-cell(12);
        margin-bottom: 3rem;

        @include breakpoint(large) {
            @include xy-cell(6);
        }

        @include breakpoint(xlarge) {
            @include xy-cell(6);
        }
    }

    &.full-width, &.featured {
        max-width: none;
        @include xy-cell(12);
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        margin-right: 0;
    
        .step-content {
            order: 2;
            padding: 0 1rem;
        }

    }    

}

.steps {
    @include xy-grid;
    @include xy-grid-layout(1, '.step');

    @include breakpoint(xxlarge) {
        @include xy-grid-layout(3, '.step');
        @include xy-grid-collapse('.step', $gutter-type:margin);
    }

    .step{
        .inner {
            @include xy-grid;
            @include xy-grid-collapse('.image .step-content', $gutter-type:margin);

            .image {
                @include xy-cell();

                @include breakpoint(large){
                    @include xy-cell(3, true, 0);
                }

                @include breakpoint(xlarge){
                    @include xy-cell(12);
                }
            }

            .step-content {
                @include xy-cell(12);
                margin-bottom: 1rem;

                @include breakpoint(large){
                    @include xy-cell(9);
                }

                @include breakpoint(xlarge){
                    @include xy-cell(12);
                    padding: .5rem 1rem 1rem;
                }
            }

        }
    }
    
}