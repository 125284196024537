.nav-alpha {
    margin-bottom: 3rem;

    .is-stuck {
        background: rgba(0, 0, 0, 0.8);
        padding: .8rem 0;
    }

    .menu {

        .menu-text {
            text-decoration: line-through;
            font-weight: normal;
            background: transparent;
        }

        a {
            &.is-active {
                background-color: $secondary-color;
                color: #fff;
            }

            padding: 0.7rem .4rem;
            text-align: center;
            margin: 2px;

        }

    }
}

.letter-group {
    margin-bottom: 2rem;
}

.letter {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.letter-result {
    padding: 1rem;
    border-top: 1px solid #eee;

    .letter-result-title {
        font-size: 24px;
        display: inline-block;
    }

    .letter-category {
        font-size: 12px;
        background-color: $secondary-color;
        display: inline-block;
        margin-left: 10px;
        color: #fff;
        padding: 2px 10px;
    }
}
