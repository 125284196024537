

  .media-object {
    
    &.media-object-block {
      background: white;
      padding: 1rem;
      border: 1px solid #e3e3e3;
      margin: 0 0 1rem 0;
    }
  }