.award-card {

    max-width: 400px;
    
    .events-card-img {
        margin-bottom: 1rem;

        img {
            width: 100%;
        }

    }

}

.award-card-list {

     @include xy-grid;

    .award-card {
        @include xy-cell(12);
        margin-bottom: 3rem;

        @include breakpoint(medium) {
            @include xy-cell(6);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
        }

    }

}
