

.grid-fluid-centered {

    @include xy-grid-container;
    text-align: center;

    .grid {
         @include xy-grid;
    }

    .grid-item {

        @include xy-cell(6);

        @include breakpoint(medium) {
            @include xy-cell(auto);
            min-width: 150px;
        }

    }

}


.grid-left {

    padding: 5rem 0;

    .intro {
        margin-bottom: 3rem;
        width: 70%;
    }

}


.grid-generic-left {

    @include xy-grid;

    .grid-item {

        margin-bottom: 3rem;
        position: relative;

        @include breakpoint(xlarge) {
            @include xy-cell(4);

        }

    }

}



.grid-centered {

    padding: 5rem 0;

    .intro {
        margin-bottom: 3rem;
        text-align: center;
    }

}


.grid-generic-centered {

    @include xy-grid;

    .grid-item {
        text-align: center;
        margin-bottom: 3rem;
        position: relative;

        @include breakpoint(xlarge) {
            @include xy-cell(4);

        }

    }

}
