.gallery-feature {
    position: relative;
    height: 50vh;
    min-height: 500px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-color: #333;
    background-position: center center;
    background-size: cover;

    .gallery-feature-content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        margin: 0 auto;
        z-index: 20;
        color: #fff;

        & > *:first-child {
            margin-top: 0;
        }

    }

    .gallery-featured-links {
        background: $primary-blue;
        opacity: .9;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        padding: 1rem;
        border-top: 1px solid #111;

        a {
            color: #fff;
            margin: 0;

            i {
                color: $secondary-yellow;
            }
        }

       

    }

    @include breakpoint(xlarge) {
        height: 80vh;
        min-height: 500px;

        .gallery-feature-content {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            margin: 0 auto;
            z-index: 20;
        }
    }

}

.gallery-featured-links {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding: 1rem;
    border-top: 1px solid #111;
    z-index: 1001;

    a {
        color: #fff !important;
        margin: 0;

        i {
            color: $secondary-yellow;
        }
    }

}

.image-gallery-toc {
    padding: .4rem 2rem;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    width:100%;
    display: none;
    @include clearfix();
    opacity: .9;

    &.is-anchored {
        animation-duration: 0.5s;
        @include mui-animation(fade(100, 0));
    }

    &.is-stuck {
        display: block;
        animation-duration: 0.5s;
        @include mui-animation(slide($direction: down, $amount: 120%));
    }

    a {
        color: #fff;
    }

    h1 {
        font-size: 1.5rem;
        float: left;
        margin-top: 8px;
    }

    .gallery-toc-links {
        float: right;

        .button {
            margin: 0;
            padding: .7rem 1rem;
            color: #fff;
        }

    }
}

.gallery-toc-links {
    i {
        color: $secondary-yellow;
    }
}

.gallery-images {

    padding: 5rem 0;

    .grid-container {
        @include clearfix();
    }

    .gallery-image {

        padding-top: 80px;

        &:first-of-type {
            padding-top: 0;
        }

        img {
            width: 100%;
        }

        @include breakpoint(xxlarge) {
            width: 45%;
            float: left;
            padding-top: 100px;
            animation-duration: .5s;
            @include mui-animation(fade, slide($direction: right, $amount: 30%));

            &:nth-child(even) {
                float: right;

                padding-top: 240px;
                @include mui-animation(fade, slide($direction: left, $amount: 30%));
            }

            &:nth-child(2n+1) {
                clear: left;
            }

        }

    }

}

.image-gallery-carousel {

    position: relative;
    padding-bottom: 75px;
    margin-bottom: 50px;
    margin-top: 1rem;

    .gallery-image {

        background-color: #000;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;

        a {
            display: block;
            height: 100%;
        }

    }

    .featured .gallery-image {
        height: 400px;
    }

    .landscape .gallery-image {
        height: 197px;
        margin-bottom: 6px;
    }

    .portrait .gallery-image {
        height: 600px;
    }

    @include breakpoint(xlarge) {
        margin-top: 3rem;

        .gallery-image {
            width: 300px;
        }

        .featured .gallery-image {
            width: 600px;
            height: 400px;
        }

        .landscape .gallery-image {
            height: 197px;
            margin-bottom: 6px;
        }

        .portrait .gallery-image {
            height: 400px;
            width: 260px;
        }

    }

}